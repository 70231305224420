<template>
  <div class="details">
    <h2 v-if="sectionData.title" v-html="sectionData.title"></h2>
    <div v-for="(item, index) in sectionData.skills" :key="index">
      <p v-html="item.title"></p>
      <ul v-if="item.bullets && item.bullets.length">
        <li v-for="(bullet, i) in item.bullets" :key="i" v-html="bullet"></li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive } from 'vue';
import useNavigationStore from '@/stores/navigation';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

interface Skill {
  title: string;
  bullets?: string[];
}

interface SectionData {
  title: string;
  skills?: Skill[];
}

@Options({
  name: 'DetailsSection',
})
export default class DetailsSection extends Vue {
  sectionData: SectionData = reactive({
    title: '',
    skills: [],
  });

  // Accès au store
  store = useNavigationStore();

  // Changement de page
  @Watch('store.currentPage')
  onCurrentPageChanged(newPage: string) {
    // eslint-disable-next-line no-console
    console.log('onCurrentPageChanged', newPage);
    this.loadSectionData(newPage);
  }

  mounted() {
    this.loadSectionData(this.store.currentPage);
  }

  // Méthode pour charger les données depuis un fichier JSON
  async loadSectionData(section: string): Promise<void> {
    // eslint-disable-next-line no-console
    await console.log('loadSectionData', section);
    if (section === 'externalPage' || section === 'unknown') {
      // unknown si le routeur n'est pas encore initialisé
      this.sectionData.title = '';
      this.sectionData.skills = [];
    } else {
      const sectionFile = `${section}.json`; // Nom du fichier basé sur la section
      try {
        const response = await fetch(`/data/${sectionFile}`);
        const data = await response.json();
        // eslint-disable-next-line no-console
        await console.log('Données chargées:', data);
        this.sectionData.title = data.title;
        this.sectionData.skills = data.skills;
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error('Erreur de chargement du fichier JSON:', error);
        this.sectionData.title = 'Erreur de chargement du fichier JSON:';
        this.sectionData.skills = [{ title: (error as Error).message }];
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.details
  flex: 3
</style>
