<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="contact-form formulaire">
    <h2>Vous souhaitez m'envoyer un email</h2>
    <form @submit.prevent="submitForm">
      <div v-if="message" class="message" :class="messageType">{{ message }}</div>
      <div v-if="!formSubmitted">
        <div class="form-group">
          <label for="nom">Votre nom&nbsp;:
            <input type="text" id="nom" v-model="nom" size="30"
              :disabled="loading || (isMenuVisible && isMobile)" required>
          </label>
        </div>
        <div class="form-group">
          <label for="email">Votre email&nbsp;:
            <input type="email" id="email" v-model="email" size="30"
              :disabled="loading || (isMenuVisible && isMobile)" required>
          </label>
        </div>
        <div class="form-group">
          <label for="sujet">Sujet&nbsp;:
            <input type="text" id="sujet" v-model="sujet" size="30"
              :disabled="loading || (isMenuVisible && isMobile)" required>
          </label>
        </div>
        <div class="form-group">
          <label for="textemsg">Message&nbsp;:
            <textarea id="textemsg" v-model="textemsg"
              :disabled="loading || (isMenuVisible && isMobile)" required></textarea>
          </label>
        </div>
        <div class="form-actions">
          <button type="submit" :disabled="loading || (isMenuVisible && isMobile)">
            <span v-if="loading" class="spinner"></span>
            <span v-else>Envoyer</span>
          </button>
          <input type="reset" value="Annuler" @click="resetForm"
            :disabled="loading || (isMenuVisible && isMobile)">
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import DeviceUtils from '@/utils/device';
import useNavigationStore from '@/stores/navigation';

@Options({
  name: 'ContactForm',
})
export default class ContactForm extends Vue {
  nom = '';

  email = '';

  sujet = '';

  textemsg = '';

  loading = false;

  formSubmitted = false;

  message = '';

  messageType = '';

  // Accès au store
  store = useNavigationStore();

  deviceUtils = DeviceUtils;

  get isMenuVisible() {
    return this.store.menuVisible;
  }

  get isMobile() {
    return this.deviceUtils.isMobile();
  }

  async submitForm() {
    this.loading = true;
    this.message = '';
    try {
      const response = await fetch('/api/send_mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nom: this.nom,
          email: this.email,
          sujet: this.sujet,
          textemsg: this.textemsg,
        }),
      });
      const result = await response.json();
      if (result.status === 'ok') {
        this.message = 'Votre demande a été prise en compte.';
        this.messageType = 'success';
        this.formSubmitted = true;
      } else {
        this.message = result.message || 'Une erreur est survenue. Veuillez réessayer plus tard.';
        this.messageType = 'error';
      }
    } catch (error) {
      this.message = 'Une erreur est survenue. Veuillez réessayer plus tard.';
      this.messageType = 'error';
    } finally {
      this.loading = false;
    }
  }

  resetForm() {
    this.nom = '';
    this.email = '';
    this.sujet = '';
    this.textemsg = '';
    this.message = '';
    this.messageType = '';
    this.formSubmitted = false;
  }
}
</script>

<style scoped lang="stylus">
.contact-form
  flex 3
  overflow-y auto

.form-actions
  display flex
  justify-content space-around

button,
input[type="reset"]
  width auto
  margin 0
</style>
