import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "details" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sectionData.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          innerHTML: _ctx.sectionData.title
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectionData.skills, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("p", {
          innerHTML: item.title
        }, null, 8, _hoisted_3),
        (item.bullets && item.bullets.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.bullets, (bullet, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: i,
                  innerHTML: bullet
                }, null, 8, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}