<template>
  <div class="formulaire">
    <h3>Obtenir mes coordonnées / Get my contact details</h3>
    <div v-if="errorMessage" class="message error">{{ errorMessage }}</div>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="codeAcces">Code d'accès / Access code
          <input type="text" id="codeAcces" v-model="codeAcces" :disabled="loading" required>
        </label>
      </div>
      <div class="form-group">
        <label for="codeEmail">Code e-mail / Email code
          <input type="text" id="codeEmail" v-model="codeEmail" :disabled="loading" required>
        </label>
      </div>
      <div class="form-actions">
        <button type="submit" :disabled="loading">
          <span v-if="loading" class="spinner"></span>
          <span v-else>Valider / Commit</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import useMentionsLegalesStore from '@/stores/mentionsLegales';

@Options({
  name: 'MentionsLegalesForm2',
})
export default class MentionsLegalesForm2 extends Vue {
  codeAcces = '';

  codeEmail = '';

  errorMessage = '';

  loading = false;

  store = useMentionsLegalesStore();

  async submitForm() {
    this.errorMessage = '';
    this.loading = true;
    try {
      const response = await fetch('/api/mentions_legales_2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codeAcces: this.codeAcces,
          codeEmail: this.codeEmail,
          token: this.store.token,
        }),
      });
      const result = await response.json();
      if (result.status === 'ok') {
        this.store.setDetail(result.detail);
        this.$emit('change-etape', 'Resultat');
      } else {
        this.errorMessage = result.message || 'Une erreur est survenue. Veuillez réessayer plus tard.';
      }
    } catch (error) {
      this.errorMessage = 'Une erreur est survenue. Veuillez réessayer plus tard.';
    } finally {
      this.loading = false;
    }
  }
}
</script>
