import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick", "onKeydown"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "consultant",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["stop"]))
      }, "☰"),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Thierry Martin consultant en informatique", -1))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["content", { 'content-overlay': _ctx.store.menuVisible && _ctx.isMobile }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["menu", { 'menu-overlay': _ctx.store.menuVisible && _ctx.isMobile }]),
        ref: "menu"
      }, [
        (_ctx.store.menuVisible)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  tabindex: "0",
                  onClick: ($event: any) => (_ctx.handleMenuClick(section.value)),
                  onKeydown: ($event: any) => (_ctx.handleKeydown(section.value, $event, index)),
                  ref_for: true,
                  ref: 'menuItem'
                }, _toDisplayString(section.label), 41, _hoisted_3))
              }), 128))
            ]))
          : (!_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _ctx.logoTm,
                  alt: "Logo de Thierry Martin consultant informatique"
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true)
      ], 2),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent)))
    ], 2)
  ]))
}