<template>
  <div class="resultat">
    <h3>Mes coordonnées / My contact details</h3>
    <div v-if="detail">
      <div class="field">
        <p class="label"><strong>Raison sociale / Company name&nbsp;:</strong></p>
        <p class="value">{{ detail.raisonSociale }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>Représentant / Representative&nbsp;:</strong></p>
        <p class="value">{{ detail.representant }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>Adresse / Address&nbsp;:</strong></p>
        <p class="value">
          <template v-for="(line, index) in detail.adresse" :key="index">
            <template v-if="index > 0"><br /></template>{{ index === 0 ? ' ' : '' }}{{ line }}
          </template>
        </p>
      </div>
      <div class="field">
        <p class="label"><strong>Code postal / Postal code&nbsp;:</strong></p>
        <p class="value">{{ detail.codePostal }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>Ville / City&nbsp;:</strong></p>
        <p class="value">{{ detail.ville }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>Pays / Country&nbsp;:</strong></p>
        <p class="value">{{ detail.pays }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>SIRET&nbsp;:</strong></p>
        <p class="value">{{ detail.siret }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>APE&nbsp;:</strong></p>
        <p class="value">{{ detail.ape }}</p>
      </div>
      <div class="field">
        <p class="label"><strong>Adresse email / Email address&nbsp;:</strong></p>
        <p class="value">{{ detail.adresseEmail }}</p>
      </div>
    </div>
    <div v-else>
      <p>Aucune information disponible.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import useMentionsLegalesStore from '@/stores/mentionsLegales';

@Options({
  name: 'MentionsLegalesResultat',
})
export default class MentionsLegalesResultat extends Vue {
  store = useMentionsLegalesStore();

  get detail() {
    return this.store.detail;
  }
}
</script>

<style scoped>
.resultat {
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;
}

.label {
  flex: 1;
  text-align: right;
  padding-right: 10px;
}

.value {
  flex: 2;
  text-align: left;
}
</style>
