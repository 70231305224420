import { defineStore } from 'pinia';

const useNavigationStore = defineStore('navigation', {
  state: () => ({
    currentPage: 'unknown', // Page actuelle inconnue, permet de forcer l'appel au changement de page
    menuVisible: false, // État de visibilité du menu
  }),

  actions: {
    // Action pour changer de page
    navigateTo(page: string) {
      this.currentPage = page;
    },
    navigateToExternalPage(url: string) {
      this.currentPage = 'externalPage';
      window.location.href = url;
    },
    initializePageFromUrl() {
      const path = window.location.pathname.slice(1);
      this.currentPage = path || 'accueil';
    },
    // Actions pour gérer la visibilité du menu
    hideMenu() {
      this.menuVisible = false;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
});

export default useNavigationStore;
