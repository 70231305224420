import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "contact-form formulaire" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "nom" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { for: "email" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { for: "sujet" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { for: "textemsg" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "form-actions" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "spinner"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Vous souhaitez m'envoyer un email", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["message", _ctx.messageType])
          }, _toDisplayString(_ctx.message), 3))
        : _createCommentVNode("", true),
      (!_ctx.formSubmitted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, [
                _cache[6] || (_cache[6] = _createTextVNode("Votre nom : ")),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "nom",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nom) = $event)),
                  size: "30",
                  disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile),
                  required: ""
                }, null, 8, _hoisted_5), [
                  [_vModelText, _ctx.nom]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, [
                _cache[7] || (_cache[7] = _createTextVNode("Votre email : ")),
                _withDirectives(_createElementVNode("input", {
                  type: "email",
                  id: "email",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                  size: "30",
                  disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile),
                  required: ""
                }, null, 8, _hoisted_8), [
                  [_vModelText, _ctx.email]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, [
                _cache[8] || (_cache[8] = _createTextVNode("Sujet : ")),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "sujet",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sujet) = $event)),
                  size: "30",
                  disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile),
                  required: ""
                }, null, 8, _hoisted_11), [
                  [_vModelText, _ctx.sujet]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, [
                _cache[9] || (_cache[9] = _createTextVNode("Message : ")),
                _withDirectives(_createElementVNode("textarea", {
                  id: "textemsg",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.textemsg) = $event)),
                  disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile),
                  required: ""
                }, null, 8, _hoisted_14), [
                  [_vModelText, _ctx.textemsg]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                type: "submit",
                disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile)
              }, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                  : (_openBlock(), _createElementBlock("span", _hoisted_18, "Envoyer"))
              ], 8, _hoisted_16),
              _createElementVNode("input", {
                type: "reset",
                value: "Annuler",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetForm && _ctx.resetForm(...args))),
                disabled: _ctx.loading || (_ctx.isMenuVisible && _ctx.isMobile)
              }, null, 8, _hoisted_19)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}