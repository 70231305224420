import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mentions-legales" }
const _hoisted_2 = {
  key: 0,
  class: "procedure"
}
const _hoisted_3 = {
  key: 1,
  class: "procedure"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Mentions légales", -1)),
    (_ctx.etape !== 'Resultat')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createStaticVNode("<h3 data-v-971e57ea>Procédure pour accéder à mes coordonnées</h3><p data-v-971e57ea> Afin d&#39;éviter que des robots ne capturent mes coordonnées, j&#39;ai mis en place une procédure interactive pour vous les communiquer. Mes coordonnées ne doivent pas être utilisées à des fins marketing, de prospection ou commerciales. </p><h4 data-v-971e57ea>Détail de la procédure :</h4><ol data-v-971e57ea><li data-v-971e57ea>Notez ou mémorisez le code d&#39;accès indiqué ci-dessous. Ce code vous sera demandé lors de la seconde étape.</li><li data-v-971e57ea>Saisissez votre adresse e-mail, qui doit être une adresse valide. Cette adresse est demandée uniquement dans le cadre de cette procédure et n’est pas collectée à des fins d’enregistrement durable. Cela signifie qu&#39;elle ne sera jamais utilisée ni pour vous contacter ultérieurement, ni pour effectuer un suivi de votre activité, ni à des fins statistiques.</li><li data-v-971e57ea>Validez le formulaire. Un e-mail vous sera envoyé et un second formulaire s&#39;affichera.</li><li data-v-971e57ea>Dans l&#39;e-mail que vous avez reçu, il y a une seconde code. Ce second code est valable 30 minutes. Si vous ne recevez pas cet e-mail, contrôlez le dossier Pourriel (ou SPAM).</li><li data-v-971e57ea>Dans le second formulaire, saisissez les informations suivantes :</li><ul data-v-971e57ea><li data-v-971e57ea>Le code d&#39;accès communiqué sur la première page.</li><li data-v-971e57ea>Le code reçu par e-mail.</li><li data-v-971e57ea>Validez la saisie.</li></ul><li data-v-971e57ea>Si vous avez bien suivi mes instructions, mes coordonnées seront affichées.</li></ol>", 4)
        ])))
      : _createCommentVNode("", true),
    (_ctx.etape !== 'Resultat')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
          _createStaticVNode("<h3 data-v-971e57ea>Procedure to access my contact details</h3><p data-v-971e57ea> To prevent robots from capturing my contact details, I have implemented an interactive procedure to communicate them to you. My contact details must not be used for marketing, prospecting or commercial purposes. </p><h4 data-v-971e57ea>Procedure details:</h4><ol data-v-971e57ea><li data-v-971e57ea>Note or memorize the access code indicated below. This code will be requested during the second step.</li><li data-v-971e57ea>Enter your email address, which must be a valid address. This address is requested only as part of this procedure and is not collected for permanent recording purposes. This means that it will never be used to contact you later, to track your activity, or for statistical purposes.</li><li data-v-971e57ea>Validate the form. An email will be sent to you and a second form will be displayed.</li><li data-v-971e57ea>In the email you received, there is a second code. This second code is valid for 30 minutes. If you do not receive this email, check the Junk (or SPAM) folder.</li><li data-v-971e57ea>In the second form, enter the following information:</li><ul data-v-971e57ea><li data-v-971e57ea>The access code provided on the first page.</li><li data-v-971e57ea>The code received by email.</li><li data-v-971e57ea>Validate the entry.</li></ul><li data-v-971e57ea>If you have followed my instructions correctly, my contact details will be displayed.</li></ol>", 4)
        ])))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), { onChangeEtape: _ctx.changeEtape }, null, 40, ["onChangeEtape"]))
  ]))
}