import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "resultat" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "field" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { class: "field" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = { class: "field" }
const _hoisted_15 = { class: "value" }
const _hoisted_16 = { class: "field" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "field" }
const _hoisted_19 = { class: "value" }
const _hoisted_20 = { class: "field" }
const _hoisted_21 = { class: "value" }
const _hoisted_22 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Mes coordonnées / My contact details", -1)),
    (_ctx.detail)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Raison sociale / Company name :")
            ], -1)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.detail.raisonSociale), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Représentant / Representative :")
            ], -1)),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.detail.representant), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Adresse / Address :")
            ], -1)),
            _createElementVNode("p", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.adresse, (line, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (index > 0)
                    ? (_openBlock(), _createElementBlock("br", _hoisted_9))
                    : _createCommentVNode("", true),
                  _createTextVNode(_toDisplayString(index === 0 ? ' ' : '') + _toDisplayString(line), 1)
                ], 64))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Code postal / Postal code :")
            ], -1)),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.detail.codePostal), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Ville / City :")
            ], -1)),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.detail.ville), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Pays / Country :")
            ], -1)),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.detail.pays), 1)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "SIRET :")
            ], -1)),
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.detail.siret), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "APE :")
            ], -1)),
            _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.detail.ape), 1)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "label" }, [
              _createElementVNode("strong", null, "Adresse email / Email address :")
            ], -1)),
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.detail.adresseEmail), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[9] || (_cache[9] = [
          _createElementVNode("p", null, "Aucune information disponible.", -1)
        ])))
  ]))
}