import { createRouter, createWebHistory } from 'vue-router';
import MartinThierry from '@/components/MartinThierry.vue';

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: MartinThierry,
  },
  {
    path: '/:section',
    name: 'Section',
    component: MartinThierry,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-console
  console.log('Navigation vers:', to.path);
  next();
});

router.afterEach((to) => {
  // eslint-disable-next-line no-console
  console.log('Route actuelle:', to.path);
});

export default router;
