import { defineStore } from 'pinia';

interface DetailMentionsLegales {
  raisonSociale: string;
  representant: string;
  adresse: string[];
  codePostal: string;
  ville: string;
  pays: string;
  siret: string;
  ape: string;
  adresseEmail: string;
}

const useMentionsLegalesStore = defineStore('mentionsLegales', {
  state: () => ({
    token: '',
    detail: {
      raisonSociale: '',
      representant: '',
      adresse: [] as string[],
      codePostal: '',
      ville: '',
      pays: '',
      siret: '',
      ape: '',
      adresseEmail: '',
    } as DetailMentionsLegales,
  }),
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    setDetail(detail: DetailMentionsLegales) {
      this.detail = detail;
    },
  },
});

export default useMentionsLegalesStore;
