// eslint-disable-next-line import/no-extraneous-dependencies
import 'modern-css-reset/dist/reset.min.css';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './styles/global.styl'; // Importer les styles globaux

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});
