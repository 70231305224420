<template>
  <div class="mentions-legales">
    <h2>Mentions légales</h2>
    <div v-if="etape !== 'Resultat'" class="procedure">
      <h3>Procédure pour accéder à mes coordonnées</h3>
      <p>
        Afin d'éviter que des robots ne capturent mes coordonnées,
        j'ai mis en place une procédure interactive pour vous les communiquer.
        Mes coordonnées ne doivent pas être utilisées à des fins marketing,
        de prospection ou commerciales.
      </p>
      <h4>Détail de la procédure&nbsp;:</h4>
      <ol>
        <li>Notez ou mémorisez le code d'accès indiqué ci-dessous.
          Ce code vous sera demandé lors de la seconde étape.</li>
        <li>Saisissez votre adresse e-mail, qui doit être une adresse valide.
          Cette adresse est demandée uniquement dans le cadre de cette procédure et
          n’est pas collectée à des fins d’enregistrement durable.
          Cela signifie qu'elle ne sera jamais utilisée ni pour vous contacter ultérieurement,
          ni pour effectuer un suivi de votre activité, ni à des fins statistiques.</li>
        <li>Validez le formulaire. Un e-mail vous sera envoyé et
          un second formulaire s'affichera.</li>
        <li>Dans l'e-mail que vous avez reçu, il y a une seconde code.
          Ce second code est valable 30 minutes.
          Si vous ne recevez pas cet  e-mail, contrôlez le dossier Pourriel (ou SPAM).</li>
        <li>Dans le second formulaire, saisissez les informations suivantes&nbsp;:</li>
          <ul>
            <li>Le code d'accès communiqué sur la première page.</li>
            <li>Le code reçu par e-mail.</li>
            <li>Validez la saisie.</li>
          </ul>
        <li>Si vous avez bien suivi mes instructions, mes coordonnées seront affichées.</li>
      </ol>
    </div>
    <div v-if="etape !== 'Resultat'" class="procedure">
      <h3>Procedure to access my contact details</h3>
      <p>
        To prevent robots from capturing my contact details,
        I have implemented an interactive procedure to communicate them to you.
        My contact details must not be used for marketing,
        prospecting or commercial purposes.
      </p>
      <h4>Procedure details:</h4>
      <ol>
        <li>Note or memorize the access code indicated below.
          This code will be requested during the second step.</li>
        <li>Enter your email address, which must be a valid address.
          This address is requested only as part of this procedure and
          is not collected for permanent recording purposes.
          This means that it will never be used to contact you later,
          to track your activity, or for statistical purposes.</li>
        <li>Validate the form. An email will be sent to you and
          a second form will be displayed.</li>
        <li>In the email you received, there is a second code.
          This second code is valid for 30 minutes.
          If you do not receive this email, check the Junk (or SPAM) folder.</li>
        <li>In the second form, enter the following information:</li>
          <ul>
            <li>The access code provided on the first page.</li>
            <li>The code received by email.</li>
            <li>Validate the entry.</li>
          </ul>
        <li>If you have followed my instructions correctly,
          my contact details will be displayed.</li>
      </ol>
    </div>
    <component :is="currentComponent" @change-etape="changeEtape" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import useMentionsLegalesStore from '@/stores/mentionsLegales';
import MentionsLegalesForm1 from './MentionsLegalesForm1.vue';
import MentionsLegalesForm2 from './MentionsLegalesForm2.vue';
import MentionsLegalesResultat from './MentionsLegalesResultat.vue';

@Options({
  name: 'MentionsLegales',
  components: {
    MentionsLegalesForm1,
    MentionsLegalesForm2,
    MentionsLegalesResultat,
  },
})
export default class MentionsLegales extends Vue {
  etape = 'Form1';

  store = useMentionsLegalesStore();

  mounted() {
    if (this.store.detail.raisonSociale) {
      this.etape = 'Resultat';
    }
  }

  get currentComponent() {
    return `MentionsLegales${this.etape}`;
  }

  changeEtape(nouvelleEtape: string) {
    this.etape = nouvelleEtape;
  }
}
</script>

<style scoped lang="stylus">
.mentions-legales
  flex: 3

.procedure
  margin-top 1rem

  h3
    margin-bottom 0.5rem

  h4
    margin-top 1rem
    margin-bottom 0.5rem

  ol, ul
    padding-left 1.5rem

  li
    margin-bottom 0.5rem
</style>
