import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "formulaire" }
const _hoisted_2 = {
  key: 0,
  class: "message error"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "codeAcces" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "email" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "form-actions" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "spinner"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Obtenir mes coordonnées / Get my contact details", -1)),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitEmail && _ctx.submitEmail(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, [
          _cache[3] || (_cache[3] = _createTextVNode("Code d'accès / Access code ")),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            id: "codeAcces",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.codeAcces) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.codeAcces]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, [
          _cache[4] || (_cache[4] = _createTextVNode("Votre adresse e-mail / Your email address ")),
          _withDirectives(_createElementVNode("input", {
            type: "email",
            id: "email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
            disabled: _ctx.loading,
            required: ""
          }, null, 8, _hoisted_7), [
            [_vModelText, _ctx.email]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "submit",
          disabled: _ctx.loading
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10))
            : (_openBlock(), _createElementBlock("span", _hoisted_11, "Valider / Commit"))
        ], 8, _hoisted_9)
      ])
    ], 32)
  ]))
}