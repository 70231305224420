<template>
  <div class="formulaire">
    <h3>Obtenir mes coordonnées / Get my contact details</h3>
    <div v-if="errorMessage" class="message error">{{ errorMessage }}</div>
    <form @submit.prevent="submitEmail">
      <div class="form-group">
        <label for="codeAcces">Code d'accès / Access code
          <input type="text" id="codeAcces" v-model="codeAcces" disabled>
        </label>
      </div>
      <div class="form-group">
        <label for="email">Votre adresse e-mail / Your email address
          <input type="email" id="email" v-model="email" :disabled="loading" required>
        </label>
      </div>
      <div class="form-actions">
        <button type="submit" :disabled="loading">
          <span v-if="loading" class="spinner"></span>
          <span v-else>Valider / Commit</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import useMentionsLegalesStore from '@/stores/mentionsLegales';

@Options({
  name: 'MentionsLegalesForm1',
})
export default class MentionsLegalesForm1 extends Vue {
  codeAcces = '';

  email = '';

  errorMessage = '';

  loading = false;

  store = useMentionsLegalesStore();

  mounted() {
    this.fetchAccessCode();
  }

  beforeUnmount() {
    this.resetForm();
  }

  async fetchAccessCode() {
    this.loading = true;
    try {
      const response = await fetch('/api/mentions_legales_0');
      const data = await response.json();
      this.codeAcces = data.code_1;
      this.store.setToken(data.token);
    } catch (error) {
      this.errorMessage = 'Erreur lors de la récupération du code d\'accès.';
    } finally {
      this.loading = false;
    }
  }

  async submitEmail() {
    this.errorMessage = '';
    this.loading = true;
    try {
      const response = await fetch('/api/mentions_legales_1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: this.email, token: this.store.token }),
      });
      const result = await response.json();
      if (result.status === 'ok') {
        this.$emit('change-etape', 'Form2');
      } else {
        this.errorMessage = result.message || 'Une erreur est survenue. Veuillez réessayer plus tard.';
      }
    } catch (error) {
      this.errorMessage = 'Une erreur est survenue. Veuillez réessayer plus tard.';
    } finally {
      this.loading = false;
    }
  }

  resetForm() {
    this.codeAcces = '';
    this.email = '';
    this.errorMessage = '';
  }
}
</script>
