<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
  <div class="consultant" @click="handleOutsideClick">
    <div class="header">
      <button @click.stop="toggleMenu">☰</button>
      <h1>Thierry Martin consultant en informatique</h1>
    </div>
    <div class="content" :class="{ 'content-overlay': store.menuVisible && isMobile }">
      <div class="menu" :class="{ 'menu-overlay': store.menuVisible && isMobile }" ref="menu">
        <ul v-if="store.menuVisible">
          <li v-for="(section, index) in sections" :key="index" tabindex="0"
            @click="handleMenuClick(section.value)"
            @keydown="handleKeydown(section.value, $event, index)"
            :ref="'menuItem'">
            {{ section.label }}
          </li>
        </ul>
        <div v-else-if="!isMobile">
          <img :src="logoTm" alt="Logo de Thierry Martin consultant informatique" />
        </div>
      </div>
      <component :is="currentComponent" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { useRoute, useRouter } from 'vue-router';
import DeviceUtils from '@/utils/device';
import useNavigationStore from '@/stores/navigation';
import sections from '@/constants/sections.json';
import DetailsSection from './DetailsSection.vue';
import ContactForm from './ContactForm.vue';
import MentionsLegales from './MentionsLegales.vue';

@Options({
  name: 'MartinThierry',
  components: {
    DetailsSection,
    ContactForm,
    MentionsLegales,
  },
})
export default class MartinThierry extends Vue {
  // Accès au store
  store = useNavigationStore();

  // Accès au routeur
  route = useRoute();

  router = useRouter();

  deviceUtils = DeviceUtils;

  windowWidth = window.innerWidth;

  currentComponent = 'DetailsSection';

  sections = sections;

  // Importer l'image
  // eslint-disable-next-line global-require
  logoTm = require('@/assets/images/logo_tm.webp');

  get isMobile() {
    return this.deviceUtils.isMobile();
  }

  mounted() {
    this.store.initializePageFromUrl();
    this.updateSectionFromRoute();
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', this.handleKeydownGlobal);
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.handleKeydownGlobal);
  }

  @Watch('$route')
  onRouteChange() {
    this.updateSectionFromRoute();
  }

  updateSectionFromRoute() {
    const section = this.route.params.section || 'accueil';
    this.selectSection((this.sections.find((s) => s.value === section) || this.sections[0]).value);
  }

  // Méthode pour basculer le menu
  toggleMenu(): void {
    this.store.toggleMenu();
  }

  // Méthode pour sélectionner une section
  selectSection(section: string): void {
    if (section === 'cv') {
      this.store.navigateToExternalPage('/cv/cv-ana-rea.php');
      return;
    }
    if (section === 'contact') {
      this.currentComponent = 'ContactForm';
    } else if (section === 'mentions_legales') {
      this.currentComponent = 'MentionsLegales';
    } else {
      this.currentComponent = 'DetailsSection';
    }
    this.store.navigateTo(section);
    this.router.push(section === 'accueil' ? '/' : `/${section}`);
  }

  // Méthode pour gérer les événements clavier
  handleKeydown(section: string, event: KeyboardEvent, index: number): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.selectSection(section);
      if (this.isMobile) {
        this.store.hideMenu();
      }
    } else if (event.key === 'Escape') {
      this.store.hideMenu();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = (index + 1) % this.sections.length;
      const nextItem = (this.$refs.menuItem as HTMLElement[])[nextIndex];
      nextItem.focus();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex = (index - 1 + this.sections.length) % this.sections.length;
      const prevItem = (this.$refs.menuItem as HTMLElement[])[prevIndex];
      prevItem.focus();
    }
  }

  // Méthode pour gérer les événements clavier globaux
  handleKeydownGlobal(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.store.menuVisible) {
      this.store.hideMenu();
    }
  }

  // Méthode pour gérer le clic sur le menu
  handleMenuClick(section: string): void {
    this.selectSection(section);
    if (this.isMobile) {
      this.store.hideMenu();
    }
  }

  // Méthode pour gérer le redimensionnement de la fenêtre
  handleResize() {
    this.windowWidth = window.innerWidth;
    if (this.isMobile && this.store.menuVisible) {
      this.store.hideMenu();
    }
  }

  // Méthode pour gérer les clics en dehors du menu
  handleOutsideClick(event: MouseEvent) {
    const menu = this.$refs.menu as HTMLElement;
    if (this.store.menuVisible && !menu.contains(event.target as Node) && this.isMobile) {
      this.store.hideMenu();
    }
  }
}
</script>

<style scoped lang="stylus">
@import '../styles/variables.styl'

.consultant
  display flex
  flex-direction column

.header
  display flex
  align-items center
  width 100%
  box-sizing border-box
  text-align center
  position relative
  z-index 20
  margin-bottom 1rem

  button
    margin 0 1rem
    text-align center

  h1
    flex 1
    font-size 1.5rem

.content
  display block
  width 100%
  position relative

  &.content-overlay
    background-color rgba(0, 0, 0, 0.5)

.menu
  margin-right 0

  &.menu-overlay
    position absolute
    top 0
    left 0
    width 100%
    height auto
    background-color rgba(255, 255, 255, 0.9)
    z-index 10

  ul
    list-style none
    padding 0.5rem
    margin 0
    background-color rgb(239, 239, 239)

    li
      padding 0.5rem
      text-align center

.details
  width: 100%

@media (min-width: $max-width-petit-smartphone + 1)
  .header
    margin-bottom 3rem

    h1
      font-size 2rem

  .content
    display flex

  .menu
    flex 1
    margin-right 20px

  .details
    flex 3
    width auto
</style>
