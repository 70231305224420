<template>
  <MartinThierry/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import MartinThierry from '@/components/MartinThierry.vue';

@Options({
  components: {
    MartinThierry,
  },
})
export default class App extends Vue {}
</script>

<style lang="stylus">
@import './styles/variables.styl'

#app
  max-width "calc(%s - 20px)" % $max-width-petit-ordi
  margin-left "calc(50vw - %s / 2 + 10px)" % $max-width-petit-ordi
  margin-right auto
  padding 30px 20px 10px 20px

  @media (max-width: $max-width-petit-ordi)
    max-width "calc(%s - 20px)" % $max-width-tablette
    margin-left "calc(50vw - %s / 2 + 10px)" % $max-width-tablette

  @media (max-width: $max-width-tablette)
    max-width "calc(%s - 20px)" % $max-width-grand-smartphone
    margin-left "calc(50vw - %s / 2 + 10px)" % $max-width-grand-smartphone

  @media (max-width: $max-width-grand-smartphone)
    max-width "calc(%s - 20px)" % $max-width-petit-smartphone
    margin-left "calc(50vw - %s / 2 + 10px)" % $max-width-petit-smartphone

  @media (max-width: $max-width-petit-smartphone)
    max-width 100%
    margin 0
    padding 5px 10px
</style>
